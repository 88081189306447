import React from "react";
import Button from "@material-ui/core/Button";
import * as classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  button: {
    textTransform: "Capitalize",
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    fontWeight: "bold",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
    "&.MuiButton-outlined": {
      background: "#FFFFFF",
    },
  },
  buttonFullWidth: {
    paddingLeft: 0,
    paddingRight: 0,
    [theme.breakpoints.down("sm")]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
}));

export const ButtonBase = (props) => {
  const classes = useStyles();

  const {
    variant,
    size,
    fullWidth,
    children,
    className,
    disabled = false,
    disableElevation = false,
  } = props;

  return (
    <Button
      {...props}
      variant={variant ?? "contained"}
      size={size ?? "large"}
      className={classNames(classes.button, className, {
        [classes.buttonFullWidth]: fullWidth ?? false,
      })}
      disabled={disabled}
      disableElevation={disableElevation}
    >
      {children}
    </Button>
  );
};
