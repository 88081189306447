import React, { useEffect, useState, useRef } from "react";
import mapboxgl from "mapbox-gl";
import { ButtonBase } from "components/ui/core/button";
import Box from "@material-ui/core/Box";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import FormSelectAuto from "components/ui/FormContent/formSelectAuto";
import CloseIcon from "@material-ui/icons/Close";
import {
  DroneIcon,
  ThreeDots,
  EditIcon,
  DeleteIcon,
  FlightDetailsIcon,
  FlightPathIcon,
} from "components/common/Icons/aerialIcons";
import TicketIcon from "@material-ui/icons/Receipt";
import ConfirmDialog from "components/ui/dialog/confirmDialog";
import Skeleton from "@material-ui/lab/Skeleton";
import {
  missionList,
  missionWorkticketList,
  missionWorkticketLink,
  missionWorkticketUnlink,
  missionDetails,
} from "services/aerialMissionService";
import { useWorkticketView } from "contexts/workticketViewContext";
import GlobalUiContext from "contexts/globalUiContext";
import { permissionWorkticket, hasPermission } from "lib/permissions";
import { logException } from "components/util/logUtil";
import zeroStateMissionIcon from "assets/icons/aerialMissions/zeroStateMission.png";
import LoadingStateHorizontal from "components/common/LoadingStateHorizontal/LoadingStateHorizontal";
import { mapBoxKey } from "lib/config";
import useStyles from "./styles";

mapboxgl.accessToken = mapBoxKey;

const DialogMission = ({ open, handleClose, fetchMissionData }) => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  const [missionsData, setMissionsData] = useState([]);
  const [missionItem, setMissionItem] = useState(null);
  const [missionDetailIsLoading, setMissionDetailIsLoading] = useState(false);
  const [missionSelected, setMissionSelected] = useState(null);
  const [stateContext] = useWorkticketView();
  const { workticket } = stateContext ?? null;

  useEffect(() => {
    const fetchData = async () => {
      try {
        // const { data } = await missionList(workticket?.job?.job_number);
        const { data } = await missionList("10001");
        const renderData = data.data.missions.map((mission) => ({
          value: mission.id,
          label: mission.name,
        }));
        setMissionsData(renderData);
        setIsLoading(false);
      } catch (error) {
        logException(error);
      }
    };
    if (workticket?.id) {
      fetchData();
    }
  }, [workticket]);

  const handleChangeMission = async (event, value) => {
    console.log({ value });
    setMissionSelected(value);
    setMissionDetailIsLoading(true);
    try {
      const missionDetailsResponse = await missionDetails(value.value);
      setMissionItem(missionDetailsResponse.data.data);
      setMissionDetailIsLoading(false);
    } catch (error) {
      logException(error);
    }
  };

  const handleAssociate = async () => {
    try {
      setIsLoading(true);
      const data = {
        mission_id: missionSelected.value,
      };
      await missionWorkticketLink(workticket.id, data);
      fetchMissionData();
      handleClose();
    } catch (error) {
      logException(error);
    }
  };

  return (
    <Dialog
      fullWidth={true}
      maxWidth={"sm"}
      open={open}
      onClose={handleClose}
      aria-labelledby="max-width-dialog-title"
    >
      {isLoading ? (
        <Box className={classes.loadingWrapper}>
          <LoadingStateHorizontal isVisible />
        </Box>
      ) : (
        <>
          <DialogContent>
            <IconButton
              aria-label="close"
              onClick={handleClose}
              className={classes.wrapperClose}
            >
              <CloseIcon className={classes.iconClose} />
            </IconButton>
            <Typography variant="h4" className={classes.titleDialogMission}>
              <DroneIcon /> Aerial Operations Mission
            </Typography>
            <Typography variant="body1" className={classes.bodyDialogMission}>
              Associate this Inspection Ticket with one of the Aerial Operations
              Mission listed below
            </Typography>
            <FormSelectAuto
              gridSizes={[{ size: "md", val: 12 }]}
              options={missionsData}
              handleChange={handleChangeMission}
              label="Mission"
              value={missionSelected}
            />
            {missionSelected ? (
              <Box className={classes.missionDetailsWrapper}>
                {missionDetailIsLoading ? (
                  <Box className={classes.missionDetailsLoader}>
                    <LoadingStateHorizontal isVisible />
                  </Box>
                ) : (
                  <Box className={classes.missionItemSection}>
                    <Box>
                      <FlightDetailsIcon className={classes.missionItemIcon} />
                    </Box>
                    <Box>
                      <Typography
                        variant="body2"
                        className={classes.missionItemSectionTitle}
                      >
                        Flight Details
                      </Typography>
                      <Box className={classes.missionDetailsContainer}>
                        <Typography
                          variant="body2"
                          className={classes.missionItemDetail}
                        >
                          Flight Time:{" "}
                          {parseFloat(
                            missionItem.duration_seconds / 60
                          ).toFixed(1)}{" "}
                          MINS
                        </Typography>
                        <Typography
                          variant="body2"
                          className={classes.missionItemDetail}
                        >
                          |
                        </Typography>
                        <Typography
                          variant="body2"
                          className={classes.missionItemDetail}
                        >
                          Flight Altitude: {missionItem.altitude_feet} FEET
                        </Typography>
                        <Typography
                          variant="body2"
                          className={classes.missionItemDetail}
                        >
                          |
                        </Typography>
                        <Typography
                          variant="body2"
                          className={classes.missionItemDetail}
                        >
                          Battery Percentage: {missionItem.battery_percentage}%
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                )}
              </Box>
            ) : null}
          </DialogContent>

          <DialogActions className={classes.actionsDialogWrapperMission}>
            <ButtonBase
              color="secondary"
              variant="outlined"
              onClick={handleClose}
            >
              Cancel
            </ButtonBase>{" "}
            <ButtonBase
              color="secondary"
              disableElevation={true}
              onClick={handleAssociate}
            >
              Associate
            </ButtonBase>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};

const DialogUpdateMission = ({
  open,
  missionItem,
  handleClose,
  fetchMissionData,
}) => {
  const classes = useStyles();
  const [missionsData, setMissionsData] = useState([]);
  const [missionSelected, setMissionSelected] = useState(null);
  const [missionItemUpdate, setMissionItemUpdate] = useState(null);
  const [missionDetailIsLoading, setMissionDetailIsLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [stateContext] = useWorkticketView();
  const { workticket } = stateContext ?? null;

  useEffect(() => {
    const fetchData = async () => {
      try {
        // const { data } = await missionList(workticket?.job?.job_number);
        const { data } = await missionList("10001");
        const renderData = data.data.missions.map((mission) => ({
          value: mission.id,
          label: mission.name,
        }));
        const selected = renderData.find(
          (mission) => mission.value === missionItem.id
        );
        if (selected) {
          setMissionSelected(selected);
        }
        setMissionItemUpdate(missionItem);
        setMissionDetailIsLoading(false);
        setMissionsData(renderData);
        setIsLoading(false);
      } catch (error) {
        logException(error);
      }
    };
    if (workticket?.id) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workticket]);

  const handleChangeMission = async (event, value) => {
    setMissionSelected(value);
    if (!value) {
      setMissionItemUpdate(null);
      return;
    }
    setMissionDetailIsLoading(true);
    try {
      const missionDetailsResponse = await missionDetails(value.value);
      setMissionItemUpdate(missionDetailsResponse.data.data);
      setMissionDetailIsLoading(false);
    } catch (error) {
      setMissionSelected(null);
      logException(error);
    }
  };

  const handleUpdateLink = async () => {
    try {
      if (missionSelected.value === missionItem.id) {
        handleClose();
        return;
      }
      setIsLoading(true);
      await missionWorkticketUnlink(workticket.id, {
        workticket_mission_id: missionItem.workticket_mission_id,
      });
      const data = {
        mission_id: missionSelected.value,
      };
      await missionWorkticketLink(workticket.id, data);
      handleClose();
      fetchMissionData();
    } catch (error) {
      logException(error);
    }
  };

  return (
    <Dialog
      fullWidth={true}
      maxWidth={"sm"}
      open={open}
      onClose={handleClose}
      aria-labelledby="max-width-dialog-title"
    >
      {isLoading ? (
        <Box className={classes.loadingWrapper}>
          <LoadingStateHorizontal isVisible />
        </Box>
      ) : (
        <>
          <DialogContent>
            <IconButton
              aria-label="close"
              onClick={handleClose}
              className={classes.wrapperClose}
            >
              <CloseIcon className={classes.iconClose} />
            </IconButton>
            <Typography variant="h4" className={classes.titleDialogMission}>
              <TicketIcon className={classes.iconDialog} /> Number
            </Typography>
            <Typography variant="body1" className={classes.bodyDialogMission}>
              Associate this Ticket with another Mission
            </Typography>
            <FormSelectAuto
              gridSizes={[{ size: "md", val: 12 }]}
              options={missionsData}
              handleChange={handleChangeMission}
              label="Mission"
              value={missionSelected}
            />
            {missionItemUpdate ? (
              <Box className={classes.missionDetailsWrapper}>
                {missionDetailIsLoading ? (
                  <Box className={classes.missionDetailsLoader}>
                    <LoadingStateHorizontal isVisible />
                  </Box>
                ) : (
                  <Box className={classes.missionItemSection}>
                    <Box>
                      <FlightDetailsIcon className={classes.missionItemIcon} />
                    </Box>
                    <Box>
                      <Typography
                        variant="body2"
                        className={classes.missionItemSectionTitle}
                      >
                        Flight Details
                      </Typography>

                      <Box className={classes.missionDetailsContainer}>
                        <Typography
                          variant="body2"
                          className={classes.missionItemDetail}
                        >
                          Flight Time:{" "}
                          {parseFloat(
                            missionItemUpdate.duration_seconds / 60
                          ).toFixed(1)}{" "}
                          MINS
                        </Typography>
                        <Typography
                          variant="body2"
                          className={classes.missionItemDetail}
                        >
                          |
                        </Typography>
                        <Typography
                          variant="body2"
                          className={classes.missionItemDetail}
                        >
                          Flight Altitude: {missionItemUpdate.altitude_feet}{" "}
                          FEET
                        </Typography>
                        <Typography
                          variant="body2"
                          className={classes.missionItemDetail}
                        >
                          |
                        </Typography>
                        <Typography
                          variant="body2"
                          className={classes.missionItemDetail}
                        >
                          Battery Percentage:{" "}
                          {missionItemUpdate.battery_percentage}%
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                )}
              </Box>
            ) : null}
          </DialogContent>

          <DialogActions className={classes.actionsDialogWrapperMission}>
            <ButtonBase
              color="secondary"
              variant="outlined"
              onClick={handleClose}
            >
              Cancel
            </ButtonBase>{" "}
            <ButtonBase
              color="secondary"
              disableElevation={true}
              onClick={handleUpdateLink}
              disabled={!missionSelected?.value}
            >
              Update link
            </ButtonBase>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};

const ZeroState = ({ handleLinkMission }) => {
  const classes = useStyles();

  return (
    <Box className={classes.zeroStateContainer}>
      <img src={zeroStateMissionIcon} alt="zero-state-missions" />
      <Typography variant="h1" className={classes.zeroStateTitle}>
        Associate Ticket with a Mission
      </Typography>
      <Typography variant="body1" className={classes.zeroStateBody}>
        Click on the button below to find Aerial Operation Missions to associate
        with this ticket.
      </Typography>
      <ButtonBase
        color="secondary"
        onClick={handleLinkMission}
        disableElevation
      >
        Link to Mission
      </ButtonBase>
    </Box>
  );
};

const FlightPathMap = ({ missionItem }) => {
  const classes = useStyles();
  const mapContainer = useRef(null);
  const map = useRef(null);

  useEffect(() => {
    if (!mapContainer.current || map.current) return;
    const pathCoordinates = missionItem?.path?.coordinates ?? [];
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: "mapbox://styles/mapbox/light-v10",
      center: pathCoordinates[0] || [0, 0],
      zoom: 15,
    });

    if (pathCoordinates.length > 0) {
      const bounds = new mapboxgl.LngLatBounds();
      pathCoordinates.forEach((coord) => bounds.extend(coord));
      map.current.fitBounds(bounds, { padding: 50 });

      map.current.on("load", () => {
        map.current.addSource("pathSource", {
          type: "geojson",
          data: {
            type: "Feature",
            geometry: {
              type: "LineString",
              coordinates: pathCoordinates,
            },
          },
        });

        map.current.addLayer({
          id: "pathLayer",
          type: "line",
          source: "pathSource",
          paint: {
            "line-color": "#328914",
            "line-width": 2,
          },
        });

        map.current.addSource("waypointsSource", {
          type: "geojson",
          data: {
            type: "FeatureCollection",
            features: pathCoordinates.map((coordinate) => ({
              type: "Feature",
              geometry: {
                type: "Point",
                coordinates: coordinate,
              },
            })),
          },
        });

        map.current.addLayer({
          id: "waypointsLayer",
          type: "circle",
          source: "waypointsSource",
          paint: {
            "circle-radius": 5,
            "circle-color": "#E8F8CB",
            "circle-stroke-width": 1,
            "circle-stroke-color": "#328914",
          },
        });

        // Add a dotted line between the start and end points
        if (pathCoordinates.length >= 2) {
          map.current.addSource("dottedLineSource", {
            type: "geojson",
            data: {
              type: "Feature",
              geometry: {
                type: "LineString",
                coordinates: [
                  pathCoordinates[0],
                  pathCoordinates[pathCoordinates.length - 1],
                ],
              },
            },
          });

          map.current.addLayer({
            id: "dottedLineLayer",
            type: "line",
            source: "dottedLineSource",
            paint: {
              "line-color": "#328914", // Replace with stylesGlobal.primaryDarkBlue
              "line-width": 1,
              "line-dasharray": [2, 2], // Dotted line pattern
            },
          });
        }

        // Add a label for the starting point with styled background and border
        map.current.addSource("startingPointSource", {
          type: "geojson",
          data: {
            type: "Feature",
            geometry: {
              type: "Point",
              coordinates: pathCoordinates[0],
            },
          },
        });

        map.current.addLayer({
          id: "startingPointLabel",
          type: "symbol",
          source: "startingPointSource",
          layout: {
            "text-field": "Starting Point",
            "text-offset": [0, 0.5],
            "text-anchor": "top",
            "text-size": 12,
            "text-padding": 0,
            "text-justify": "center",
          },
          paint: {
            "text-color": "#2e7d32",
            "text-halo-color": "#dcedc8",
            "text-halo-width": 10,
            "text-halo-blur": 1,
          },
        });
      });
    }

    return () => {
      if (map.current) {
        map.current.remove();
        map.current = null;
      }
    };
  }, [missionItem]);

  return (
    <Box>
      <Box ref={mapContainer} className={classes.mapContainer} />
    </Box>
  );
};

const MissionItem = ({ missionItem, handleEdit, handleDelete }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  const menuItems = [
    {
      label: "Change Mission",
      icon: <EditIcon />,
      onClick: () => handleEdit(),
      disabled: false,
      hide: false,
    },
    {
      label: "Delete",
      icon: <DeleteIcon />,
      onClick: () => handleDelete(),
      disabled: false,
      hide: false,
    },
  ];

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <Box className={classes.missionItemContainer}>
      <Box className={classes.missionItemHeader}>
        <Typography variant="h4" className={classes.missionHeaderTitle}>
          Mission Details
        </Typography>
        <Box
          onClick={(event) => {
            handleMenuClick(event);
          }}
          className={classes.editButton}
        >
          <ThreeDots />
        </Box>
      </Box>
      <Box className={classes.missionItem}>
        <Typography variant="h6" className={classes.missionItemTitle}>
          {missionItem.name}
        </Typography>
        <Box className={classes.missionItemSection}>
          <Box>
            <FlightDetailsIcon className={classes.missionItemIcon} />
          </Box>
          <Box>
            <Typography
              variant="body2"
              className={classes.missionItemSectionTitle}
            >
              Flight Details
            </Typography>
            <Typography variant="body2" className={classes.missionItemDetail}>
              Flight Time:{" "}
              {parseFloat(missionItem.duration_seconds / 60).toFixed(1)} MINS
            </Typography>
            <Typography variant="body2" className={classes.missionItemDetail}>
              Flight Altitude: {missionItem.altitude_feet} FEET
            </Typography>
            <Typography variant="body2" className={classes.missionItemDetail}>
              Battery Percentage: {missionItem.battery_percentage}%
            </Typography>
          </Box>
        </Box>
        <Box className={classes.missionItemSection}>
          <Box>
            <FlightPathIcon className={classes.missionItemIcon} />
          </Box>
          <Box>
            <Typography
              variant="body2"
              className={classes.missionItemSectionTitle}
            >
              Flight Path
            </Typography>
          </Box>
        </Box>
        <FlightPathMap missionItem={missionItem} />
      </Box>
      {/* <ButtonBase
        color="secondary"
        variant="contained"
        fullWidth
        size="medium"
        className={classes.startCheckButton}
      >
        Start Check
      </ButtonBase> */}
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        {menuItems.map(({ label, icon, onClick, disabled, hide }) => (
          <MenuItem
            key={label}
            onClick={onClick}
            style={{
              display: hide ? "none" : "flex",
            }}
          >
            <Box className={classes.menuIcon}>{icon}</Box>
            <Typography variant="caption" className={classes.menuText}>
              {label}
            </Typography>
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};

const WorkticketAerialMissions = () => {
  const classes = useStyles();
  const [missionItem, setMissionItem] = useState(null);
  const [isLoadingMission, setIsLoadingMission] = useState(true);
  const [openMissionAssociate, setOpenMissionAssociate] = useState(false);
  const [openMissionUpdate, setOpenMissionUpdate] = useState(false);
  const [isConfirmDeleteOpen, setIsConfirmDeleteOpen] = useState(false);
  const [stateContext] = useWorkticketView();
  const { workticket, isLoading } = stateContext ?? null;

  const fetchData = async () => {
    try {
      if (workticket?.id) {
        setIsLoadingMission(true);
        const { data } = await missionWorkticketList(workticket.id);
        const missionResponse = data.data.missions[0];
        if (missionResponse) {
          const missionDetailsResponse = await missionDetails(
            missionResponse.mission_id
          );
          setMissionItem({
            ...missionDetailsResponse.data.data,
            id: missionResponse.mission_id,
            workticket_mission_id: missionResponse.workticket_mission_id,
          });
        }
        setIsLoadingMission(false);
      }
    } catch (error) {
      logException(error);
    }
  };

  useEffect(() => {
    if (
      workticket?.id &&
      Number(workticket.service_type) === 2 &&
      workticket?.inspection_types?.includes("3")
    ) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workticket]);

  const handleConfirmDelete = async () => {
    try {
      setIsConfirmDeleteOpen(false);
      setMissionItem(null);
      await missionWorkticketUnlink(workticket.id, {
        workticket_mission_id: missionItem.workticket_mission_id,
      });
    } catch (error) {
      logException(error);
    }
  };
  const handleCloseConfirmDelete = () => {
    setIsConfirmDeleteOpen(false);
  };

  if (isLoading) {
    return (
      <Skeleton
        animation="wave"
        variant="rect"
        height={30}
        style={{ marginTop: 20, marginBottom: 20 }}
      />
    );
  }

  if (
    Number(workticket?.service_type) !== 2 ||
    !workticket?.inspection_types?.includes("3")
  ) {
    return null;
  }

  if (isLoadingMission) {
    return (
      <Box className={classes.loadingWrapperSm}>
        <LoadingStateHorizontal isVisible />
      </Box>
    );
  }

  return (
    <Box className={classes.containerMissions}>
      {!missionItem ? (
        <ZeroState
          handleLinkMission={() => {
            setOpenMissionAssociate(true);
          }}
        />
      ) : (
        <MissionItem
          missionItem={missionItem}
          handleEdit={() => {
            setOpenMissionUpdate(true);
          }}
          handleDelete={() => {
            setIsConfirmDeleteOpen(true);
          }}
        />
      )}

      {openMissionAssociate ? (
        <DialogMission
          open={openMissionAssociate}
          handleClose={() => {
            setOpenMissionAssociate(false);
          }}
          fetchMissionData={() => fetchData()}
        />
      ) : null}
      {openMissionUpdate ? (
        <DialogUpdateMission
          open={openMissionUpdate}
          missionItem={missionItem}
          handleClose={() => {
            setOpenMissionUpdate(false);
          }}
          fetchMissionData={() => fetchData()}
        />
      ) : null}
      {isConfirmDeleteOpen ? (
        <ConfirmDialog
          open={isConfirmDeleteOpen}
          message="Are you sure you want to unlink the mission?"
          title="Unlink Mission"
          handleClose={handleCloseConfirmDelete}
          handleConfirm={handleConfirmDelete}
        />
      ) : null}
    </Box>
  );
};

export default WorkticketAerialMissions;
