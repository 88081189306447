import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

export const DroneIcon = () => (
  <SvgIcon
    width="28"
    height="16"
    viewBox="0 0 28 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.234 3.96558H10.6136C10.1838 3.96558 9.8349 4.3535 9.8349 4.83126V8.43077C9.8349 10.3704 11.2527 11.9466 12.9973 11.9466H14.8503C16.5949 11.9466 18.0127 10.3704 18.0127 8.43077V4.83126C18.0127 4.3535 17.6638 3.96558 17.234 3.96558Z"
      fill="#4D4D4D"
      stroke="#4D4D4D"
      strokeWidth="0.538697"
      strokeMiterlimit="10"
    />
    <path
      d="M5.56517 5.60938H9.83496V7.2754H5.56517V5.60938Z"
      fill="#4D4D4D"
      stroke="#4D4D4D"
      strokeWidth="0.538697"
      strokeMiterlimit="10"
    />
    <path
      d="M5.56152 7.27515V4.68219C5.56152 4.07173 5.11526 3.57559 4.56616 3.57559C4.01705 3.57559 3.57079 4.07173 3.57079 4.68219V6.07055C3.57079 6.73614 4.05562 7.27515 4.65431 7.27515H5.56152Z"
      fill="#4D4D4D"
      stroke="#4D4D4D"
      strokeWidth="0.538697"
      strokeMiterlimit="10"
    />
    <path
      d="M4.56641 1.11523V3.57548"
      stroke="#4D4D4D"
      strokeWidth="0.886096"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M0.999972 2.15674H8.13281"
      stroke="#4D4D4D"
      strokeWidth="0.886096"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M22.2825 7.27515L18.0127 7.27515V5.60912L22.2825 5.60912V7.27515Z"
      fill="#4D4D4D"
      stroke="#4D4D4D"
      strokeWidth="0.538697"
      strokeMiterlimit="10"
    />
    <path
      d="M24.2769 6.06884V4.68048C24.2769 4.07002 23.8306 3.57389 23.2815 3.57389C22.7324 3.57389 22.2861 4.07002 22.2861 4.68048V7.27344H23.1933C23.792 7.27344 24.2769 6.73443 24.2769 6.06884Z"
      fill="#4D4D4D"
      stroke="#4D4D4D"
      strokeWidth="0.538697"
      strokeMiterlimit="10"
    />
    <path
      d="M23.2817 1.11523V3.57548"
      stroke="#4D4D4D"
      strokeWidth="0.886096"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M26.8481 2.15674H19.7153"
      stroke="#4D4D4D"
      strokeWidth="0.886096"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M17.328 10.7935L18.8945 11.6714C19.1957 11.8408 19.3867 12.1839 19.3867 12.5595V14.8847"
      stroke="#4D4D4D"
      strokeWidth="0.886096"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M10.5566 10.7935L8.99013 11.6714C8.68895 11.8408 8.49796 12.1839 8.49796 12.5595V14.8847"
      stroke="#4D4D4D"
      strokeWidth="0.886096"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M13.9232 9.43318C13.126 9.43318 12.4798 8.7147 12.4798 7.82841C12.4798 6.94211 13.126 6.22363 13.9232 6.22363C14.7204 6.22363 15.3667 6.94211 15.3667 7.82841C15.3667 8.7147 14.7204 9.43318 13.9232 9.43318Z"
      fill="white"
      stroke="#4D4D4D"
      strokeWidth="0.538697"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
  </SvgIcon>
);

export const ThreeDots = () => (
  <SvgIcon
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_455_3825)">
      <path
        d="M6.6084 10C5.5084 10 4.6084 10.9 4.6084 12C4.6084 13.1 5.5084 14 6.6084 14C7.7084 14 8.6084 13.1 8.6084 12C8.6084 10.9 7.7084 10 6.6084 10ZM18.6084 10C17.5084 10 16.6084 10.9 16.6084 12C16.6084 13.1 17.5084 14 18.6084 14C19.7084 14 20.6084 13.1 20.6084 12C20.6084 10.9 19.7084 10 18.6084 10ZM12.6084 10C11.5084 10 10.6084 10.9 10.6084 12C10.6084 13.1 11.5084 14 12.6084 14C13.7084 14 14.6084 13.1 14.6084 12C14.6084 10.9 13.7084 10 12.6084 10Z"
        fill="#747474"
      />
    </g>
    <defs>
      <clipPath id="clip0_455_3825">
        <rect
          width="24"
          height="24"
          fill="white"
          transform="translate(0.608398)"
        />
      </clipPath>
    </defs>
  </SvgIcon>
);

export function EditIcon() {
  return (
    <svg
      width="13"
      height="13"
      viewBox="0 0 13 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_33_6095)">
        <path
          d="M10.8888 1.4977C10.3762 0.98514 9.54519 0.98514 9.03263 1.4977L8.45405 2.07628L10.3102 3.93244L10.8888 3.35386C11.4013 2.8413 11.4013 2.01027 10.8888 1.4977Z"
          fill="#747474"
        />
        <path
          d="M9.77987 4.46277L7.92372 2.60661L1.8483 8.68203C1.5399 8.99043 1.31321 9.3708 1.1887 9.78878L0.788809 11.1312C0.749499 11.2632 0.785673 11.4061 0.883038 11.5034C0.980402 11.6008 1.1233 11.637 1.25526 11.5977L2.59771 11.1978C3.01569 11.0733 3.39607 10.8466 3.70446 10.5382L9.77987 4.46277Z"
          fill="#747474"
        />
      </g>
      <defs>
        <clipPath id="clip0_33_6095">
          <rect
            width="12"
            height="12"
            fill="white"
            transform="translate(0.0231934 0.363281)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export function DeleteIcon(props) {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {props.noBg !== false && (
        <rect
          x="0.132812"
          y="0.636719"
          width="24"
          height="24"
          rx="4"
          fill={props.noBg ? "#F8F8F8" : "#ECECEC"}
        />
      )}

      <path
        d="M13.8074 10.5L13.5766 16.5M10.3843 16.5L10.1535 10.5M16.7989 8.36038C17.0269 8.39481 17.2541 8.43165 17.4805 8.47086M16.7989 8.36038L16.087 17.615C16.0269 18.3965 15.3752 19 14.5914 19H9.36952C8.58571 19 7.93405 18.3965 7.87393 17.615L7.16204 8.36038M16.7989 8.36038C16.0346 8.24496 15.2615 8.15657 14.4805 8.09622M6.48047 8.47086C6.70685 8.43165 6.93405 8.39481 7.16204 8.36038M7.16204 8.36038C7.92632 8.24496 8.69946 8.15657 9.48047 8.09622M14.4805 8.09622V7.48546C14.4805 6.69922 13.8734 6.04282 13.0875 6.01768C12.7199 6.00592 12.3509 6 11.9805 6C11.61 6 11.241 6.00592 10.8734 6.01768C10.0876 6.04282 9.48047 6.69922 9.48047 7.48546V8.09622M14.4805 8.09622C13.6555 8.03247 12.8218 8 11.9805 8C11.1392 8 10.3054 8.03247 9.48047 8.09622"
        stroke="#747474"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function FlightDetailsIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <g clip-path="url(#clip0_1_3749)">
        <path
          d="M10 7.5H13.3333C13.7917 7.5 14.1667 7.125 14.1667 6.66667C14.1667 6.20833 13.7917 5.83333 13.3333 5.83333H10C9.54167 5.83333 9.16667 6.20833 9.16667 6.66667C9.16667 7.125 9.54167 7.5 10 7.5ZM10 10.8333H13.3333C13.7917 10.8333 14.1667 10.4583 14.1667 10C14.1667 9.54167 13.7917 9.16667 13.3333 9.16667H10C9.54167 9.16667 9.16667 9.54167 9.16667 10C9.16667 10.4583 9.54167 10.8333 10 10.8333ZM10 14.1667H13.3333C13.7917 14.1667 14.1667 13.7917 14.1667 13.3333C14.1667 12.875 13.7917 12.5 13.3333 12.5H10C9.54167 12.5 9.16667 12.875 9.16667 13.3333C9.16667 13.7917 9.54167 14.1667 10 14.1667ZM5.83333 5.83333H7.5V7.5H5.83333V5.83333ZM5.83333 9.16667H7.5V10.8333H5.83333V9.16667ZM5.83333 12.5H7.5V14.1667H5.83333V12.5ZM16.6667 2.5H3.33333C2.875 2.5 2.5 2.875 2.5 3.33333V16.6667C2.5 17.125 2.875 17.5 3.33333 17.5H16.6667C17.125 17.5 17.5 17.125 17.5 16.6667V3.33333C17.5 2.875 17.125 2.5 16.6667 2.5ZM15.8333 15.8333H4.16667V4.16667H15.8333V15.8333Z"
          fill="#D9D9D9"
        />
      </g>
      <defs>
        <clipPath id="clip0_1_3749">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export function FlightPathIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M7.5 5.62494V12.4999M12.5 7.49994V14.3749M12.9193 17.2903L16.9818 15.2591C17.2994 15.1003 17.5 14.7756 17.5 14.4205V4.01685C17.5 3.31993 16.7666 2.86665 16.1432 3.17832L12.9193 4.79031C12.6553 4.92227 12.3447 4.92227 12.0807 4.79031L7.91926 2.70957C7.65533 2.5776 7.34467 2.5776 7.08074 2.70957L3.01824 4.74082C2.70063 4.89963 2.5 5.22425 2.5 5.57935V15.983C2.5 16.68 3.23342 17.1332 3.85676 16.8216L7.08074 15.2096C7.34467 15.0776 7.65533 15.0776 7.91926 15.2096L12.0807 17.2903C12.3447 17.4223 12.6553 17.4223 12.9193 17.2903Z"
        stroke="#D9D9D9"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
