import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  containerAlert: {
    fontSize: 14,
    border: "none",
  },
  rootLayout: {
    height: "100%",
  },
  fullHeightTable: {
    flex: "1 1",
    height: "80vh",
    maxWidth: "100%",
    overflowX: "hidden",
    "& table": {
      minWidth: "100%",
    },
    "&::-webkit-scrollbar": {
      width: "0.4em",
      backgroundColor: "#d4d4d4",
    },
    "&::-webkit-scrollbar-track": {
      "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#8a8c8e",
      borderRadius: 5,
      outline: "1px solid slategrey",
    },
    [theme.breakpoints.down("sm")]: {
      height: "80vh",
    },
  },
  contentCounter: {
    textAlign: "right",
    marginTop: theme.spacing(1),
    "& .MuiChip-outlined": {
      border: "none",
    },
    "& .MuiChip-label": {
      paddingRight: 0,
      fontWeight: "bold",
    },
    [theme.breakpoints.down("sm")]: {
      left: 20,
      top: 75,
    },
  },
  headerContainer: {
    backgroundColor: "#f8f8f8",
    paddingTop: 50,
    paddingLeft: 30,
    paddingBottom: 20,
    minWidth: "450px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  headerContainerSteps: {
    position: "absolute",
    right: theme.spacing(3),
    top: theme.spacing(3),
    fontSize: 14,
  },
  headerContainerTitle: {
    fontSize: 18,
    marginBottom: 15,
    fontWeight: "normal",
  },
  headerContainerSubTitle: {
    fontSize: 14,
    fontWeight: "normal",
  },
  optionWrapper: {
    padding: "50px 30px 50px 30px",
  },
  containerInformationCreate: {
    padding: 30,
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    "& > div > div": {
      marginBottom: 16,
    },
  },
  containerInnerWrapper: {
    flex: 1,
  },
  drawerWrapper: {
    "& .MuiDrawer-paper": {
      width: 455,
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      "& .MuiDrawer-paper": {
        width: "100%",
      },
    },
  },
  buttonClose: {
    position: "absolute",
    top: 0,
    left: 0,
    color: "#979797",
  },
  iconClose: {
    fontSize: 16,
  },
  groupOption: {
    color: "#4f98bc",
    "& > button": {
      color: "#4f98bc",
      textTransform: "capitalize",
      marginTop: theme.spacing(1),
    },
  },
  groupOptionActive: {
    backgroundColor: theme.palette.secondary.main,
    color: "#ffffff !important",
    "&:hover": {
      backgroundColor: "#417d9b",
    },
  },
  actionNavigator: {
    marginTop: theme.spacing(4),
    textAlign: "right",
    "& > button": {
      minWidth: 140,
    },
  },
  formInputContainer: {
    "& label": {
      fontSize: 14,
      color: "#747474",
      fontWeight: "bold",
    },
  },
  inputLabel: {
    fontSize: "14px !important",
    color: "#747474 !important",
    fontWeight: "bold !important",
    marginBottom: 5,
  },
  taskItemFieldset: {
    width: "100%",
  },
  taskItemContainer: {
    marginTop: "10px !important",
    "& > label:last-child": {
      borderBottom: "1px solid #f8f8f8",
    },
  },
  taskItem: {
    borderTop: "1px solid #f8f8f8",
    marginLeft: "0 !important",
    marginRight: "0 !important",
    width: "100%",
    "& > span": {
      fontSize: 14,
    },
    "& > .MuiCheckbox-root": {
      color: "#c4c4c4",
    },
    "& .MuiFormControlLabel-label": {
      display: "block",
      width: "100%",
    },
    "& .Mui-checked + span": {
      textDecoration: "line-through",
    },
  },
  taskItemAdd: {
    backgroundColor: "#ececec",
    "& > .MuiFormControlLabel-label": {
      width: "100%",
    },
  },
  taskLabel: {
    paddingTop: 12,
    paddingBottom: 12,
    paddingRight: 34,
    position: "relative",
  },
  inputNewTask: {
    width: "100%",
    backgroundColor: "#ececec",
    "& > input": {
      backgroundColor: "#ececec",
      width: "98%",
    },
  },
  taskBoxActions: {
    marginTop: 8,
    textAlign: "right",
  },
  deleteIcon: {
    position: "absolute",
    top: 0,
    right: 0,
  },
  taskContainerEmpty: {
    backgroundColor: "#ececec",
    padding: theme.spacing(1),
    width: "100%",
  },
  buttonBack: {
    paddingLeft: 3,
    paddingRight: 3,
    minWidth: "auto !important",
    float: "left",
  },
  wrapperLabel: {
    marginTop: theme.spacing(2),
  },
  inputWrapper: {
    marginTop: theme.spacing(2),
  },
  infoWrapper: {
    display: "flex",
    paddingTop: 28,
    [theme.breakpoints.down("sm")]: {
      paddingTop: 0,
    },
    "& span": {
      paddingLeft: 4,
      paddingTop: 4,
    },
    "& > div > div > div > div": {
      [theme.breakpoints.down("sm")]: {
        marginBottom: 0,
      },
    },
  },
  iconGreen: {
    color: "#9cbc65",
  },
  iconYellow: {
    color: "#efc945",
  },
  iconBlue: {
    color: "#79c1ce",
  },
  actionPreviewWorkticket: {
    textAlign: "center",
  },
  btnPreview: {
    textTransform: "capitalize",
    padding: 20,
  },
  skipContainer: {
    // marginTop: 20,
    // paddingTop:20,
    "& .MuiFormControlLabel-label": {
      fontSize: 14,
    },
  },
  endDateContainer: {
    position: "relative",
    top: -16,
    paddingTop: 20,
    "& .MuiFormControlLabel-label": {
      fontSize: 14,
    },
  },
  filterCheckbox: {
    color: "#ececec",
    border: "none",
    // paddingBottom: 3,
    "& + .MuiFormControlLabel-label": {
      fontSize: 14,
    },
    padding: 0,
  },
  appBarWorkticketPreview: {
    position: "relative",
  },
  appBarTitle: {
    marginLeft: theme.spacing(2),
    flex: 1,
    textAlign: "right",
  },
  previewContent: {
    padding: theme.spacing(2),
  },
  previewTableTitle: {
    fontWeight: "bold",
    "& svg": {
      position: "relative",
      bottom: -8,
    },
  },
  previewTableHeader: {
    "& th": {
      fontWeight: "bold",
    },
  },
  previewTableTicketCol: {
    width: 60,
  },
  summaryContainer: {
    marginBottom: theme.spacing(3),
  },
  summaryInnerContainer: {
    borderColor: "#ececec",
    borderWidth: 1,
    borderStyle: "solid",
    padding: theme.spacing(1),
    borderRadius: 3,
    display: "flex",
    height: 44,
  },
  summaryInnerGrayContainer: {
    borderColor: "#ececec",
    backgroundColor: "#f8f8f8",
    borderWidth: 1,
    borderStyle: "solid",
    paddingTop: 8,
    paddingBottom: 4,
    borderRadius: 3,
    display: "flex",
    minHeight: 40,
  },
  summaryField: {
    flex: 1,
  },
  summaryFieldText: {
    textAlign: "right",
    fontSize: 16,
    fontWeight: "normal",
  },
  summaryIcon: {
    width: 40,
    textAlign: "center",
  },
  summaryDesc: {
    width: 180,
    paddingTop: 4,
  },
  summaryDetail: {
    flex: 1,
    fontSize: 16,
  },
  chip: {
    height: 21,
    borderRadius: 3,
    fontSize: 10,
    minWidth: 110,
  },
  chipActive: {
    backgroundColor: "#9cbb65",
    color: "#fff",
  },
  chipStopped: {
    backgroundColor: "#f07a8c",
    color: "#fff",
  },
  fileWrapper: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  loadingText: {
    fontSize: 14,
    textAlign: "center",
    padding: 20,
  },
  loadingTable: {
    width: "72%",
    bottom: 0,
    position: "absolute",
  },
  centerLoadingSideBar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flex: "1",
    margin: 0,
    padding: 0,
    height: "80vh",
    overflowY: "hidden",
  },
  centerLoadingDraw: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flex: "1",
    marginTop: 30,
  },
  popoverTimeMainContainer: {
    width: 300,
    padding: 20,
  },
  timeSlotsContainer: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    gap: 10,
    backgroundColor: "#F8F8F8",
    minHeight: 114,
    padding: 12,
  },
  bigTimeBoxesContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    justifyItems: "center",
    alignItems: "center",
    marginBottom: 10,
    paddingTop: 10,
    paddingBottom: 10,
  },
  timeBoxFunctionText: {
    fontWeight: 600,
    fontSize: 10,
    color: "#747474",
  },
  timeBoxFunctionTextSelected: {
    fontWeight: 600,
    fontSize: 10,
    color: "#4F98BC",
  },
  bigTimeBoxContainer: {
    display: "flex",
    flexDirection: "column",
    paddingTop: 10,
    paddingBottom: 10,
    alignItems: "center",
    borderRadius: 4,
    width: 110,
    border: "1px solid #D9D9D9",
    justifyContent: "center",
    "&:hover": {
      cursor: "pointer",
      borderColor: "#4F98BC",
    },
  },
  bigTimeBoxContainerSelected: {
    display: "flex",
    flexDirection: "column",
    paddingTop: 10,
    paddingBottom: 10,
    alignItems: "center",
    borderRadius: 4,
    width: 110,
    border: "1px solid #4F98BC",
    justifyContent: "center",
  },
  semiColons: {
    fontSize: 20,
    fontWeight: 500,
    color: "#747474",
    textAlign: "center",
  },
  digitContainer: {
    display: "flex",
    flexDirection: "row",
    gap: 2,
  },
  selectTimeText: {
    fontSize: 12,
    fontWeight: 600,
    color: "#747474",
  },
  digitElement: {
    color: "#747474",
    borderBottom: "1px solid #D9D9D9",
  },
  unselectedTimeBox: {
    backgroundColor: "#FFFFFF",
    padding: "4px 8px 4px 8px",
    color: "#A6A6A6CC",
    minWidth: 44,
    height: 26,
    fontSize: 14,
    fontWeight: 400,
    cursor: "pointer",
    border: "1px solid #D9D9D9",
    borderRadius: 6,
    "&:hover": {
      backgroundColor: "#4F98BC",
      cursor: "pointer",
      color: "#FFFFFF",
      border: "none",
      fontWeight: 600,
    },
  },
  selectedTimeBox: {
    backgroundColor: "#4F98BC",
    padding: "4px 8px 4px 8px",
    color: "#FFFFFF",
    minWidth: 44,
    height: 26,
    fontSize: 14,
    fontWeight: 600,
    cursor: "pointer",
    border: "none",
    borderRadius: 6,
  },
  daysPopOverContainer: {
    width: "364px",
    padding: "10px",
  },
  intervalWrapper: {
    display: "flex",
    flexDirection: "column",
    gap: 10,
  },
  timeAllotmentBillingUnitsContainer: {
    display: "flex",
    flexDirection: "row",
    gap: 10,
  },
  willBeBilledNextMonthText: {
    fontSize: 12,
    color: "#4F98BC",
    fontWeight: 600,
    marginBottom: 16,
  },
  checkbox: {
    color: "#D9D9D9",
    border: "none",

    "& + .MuiFormControlLabel-label": {
      fontSize: 14,
      marginLeft: 0,
      color: "#747474",
    },
  },
  checkboxLabel: {
    color: "#747474 !important",
    paddingBottom: 5,
    fontSize: 14,
    fontWeight: "bold",
  },
  flexAlignCenter: {
    display: "flex",
    alignItems: "center",
  },
  checkboxItem: {
    padding: 0,
  },
  labelCheckbox: {
    marginLeft: theme.spacing(1),
    fontSize: 14,
  },
  infoAlertAerial: {
    border: 0,
    marginTop: 4,
    fontSize: 14,
  },
}));

export default useStyles;
