import http from "./httpService";
import { apiUrl } from "lib/config";

export function missionList(job_number) {
  return http.get(
    `${apiUrl}/aerial-inspections/missions?job_number=${job_number}`
  );
}

export function missionDetails(mission_id) {
  return http.get(`${apiUrl}/aerial-inspections/missions/${mission_id}`);
}

export function missionWorkticketLink(workticket_id, data) {
  return http.post(
    `${apiUrl}/aerial-inspections/workticket/${workticket_id}/link`,
    data
  );
}

export function missionWorkticketUnlink(workticket_id, data) {
  return http.post(
    `${apiUrl}/aerial-inspections/workticket/${workticket_id}/unlink`,
    data
  );
}

export function missionWorkticketList(workticket_id) {
  return http.get(
    `${apiUrl}/aerial-inspections/workticket/${workticket_id}/missions`
  );
}
